import CryptoJS from "crypto-js";
import React from "react";
const Key = process.env.REACT_APP_ENC_KEY;
function encryptData(data) {
  
    //console.log("enckey",process.env);
    var b64 = CryptoJS.AES.encrypt(data, Key).toString();
    var e64 = CryptoJS.enc.Base64.parse(b64);
    var eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
    // console.log('process.env.ENCRYPT_KEY',Key)
    // return convert.textToHex(CryptoJS.AES.encrypt(JSON.stringify(data),Key).toString());
}
function decryptData(data) {
    // var bytes = CryptoJS.AES.decrypt(data, Key);
    // return convert.hexToUtf8(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
    var reb64 = CryptoJS.enc.Hex.parse(data);
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, Key);
    var plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
}
function SetLocalStorage(data)
{
    localStorage.setItem('token',encryptData(JSON.stringify(data)));
}

function GetLocalStorage()
{
    // console.log('testValue',JSON.parse(decryptData(localStorage.getItem('token'))).userName);
 return JSON.parse(decryptData(localStorage?.getItem('token')));    
}
function ClearLocalStorage()
{
    localStorage.removeItem('token');
    localStorage.clear();
}
export { encryptData, decryptData,SetLocalStorage,GetLocalStorage,ClearLocalStorage }