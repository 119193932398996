import { createStore,applyMiddleware } from 'redux';
//createStore-Creates a Redux store that holds the complete state tree of your app. There should only be a single store in your app
//Commonly, middlewares are used to deal with asynchronous actions in your app. Redux provides with API called applyMiddleware which allows us to use custom middleware as well as Redux middlewares like redux-thunk and redux-promise. It applies middlewares to store.
import { createLogger } from 'redux-logger';
//createLogger-This library logs actions in developer console, giving traceable stack of user actions
import {thunk} from 'redux-thunk';
//commonly used for data fetching
import rootReducer from '../_reducers/index'

const loggerMiddleware=createLogger();
export const store=createStore(
    rootReducer,
    applyMiddleware(
        thunk,
        loggerMiddleware
    )
)



