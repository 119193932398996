export class LoginModel {
    username: string;
    password: string;
    userType: number;

    constructor() {
        this.username = '';
        this.password = '';
        this.userType = 3;
    }
}