import React from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData,  verifytoken } from '../../services/ApiCallingUserServices';
import {  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { GetMenuIdFromStorage } from '../commonClass/Menudect';

const Userrights = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userrights, setUserRights] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState(0)
    const [toggleAdmin, setToggleRowAdmin] = useState([]);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [savedata, setDataSave] = useState({
        UserMasterId: 0,
        UserRightsList: []
    })
    const handleExpandAdmin = (e) => {
        const doesExist = toggleAdmin.includes(e);
        if (doesExist === false) {
            setToggleRowAdmin(current => [...current, e]);
        }
        else {
            setToggleRowAdmin((oldState) => oldState?.filter((item) => item !== e));

        }
    }
    const getUserList = async () => {
        await getAllData('/auth/v1/binduserlistforrights').then((userdata) => {
            setUserList(userdata.data?.map((el) => ({
                label: el.label,
                value: el.value
            })))
        })
    }
    const getUserRigths = async () => {
        await getAllData('/auth/v1/bindrightslistdata').then((userdata) => {
            setUserRights(userdata.data);
        })
    }
    const getUserById = async () => {
        setUserRights([]);
        const usermasterid = userId;
        await getAllData('/auth/v1/bindrightslistforuserrights?usermasterid=' + usermasterid).then((userdata) => {
            setUserRights(userdata.data);
            setDataSave({ ...savedata, UserMasterId: userId, UserRightsList: userdata.data });
            dispatch(loaderActions.end());
        })
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x =>{
            if( x.PageLink === window.location.pathname)
                return  x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView!== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);
          
        });
      
    }
    const handleUserchange = (e) => {
        // when select the same value from drop-down than "e.value or userId" doesn't change the value. Therefore, there is no effect in below useEffect with userId dependancy.
        // And here we clear the userrights in drop-down on change function.
        // setUserRights([]);
        setUserId(e.value);
    }
    useEffect(() => {
        setUserRights([]);
        dispatch(loaderActions.start());
        getUserById();
    }, [userId])


    const handlesavadata = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            let status = false;
            let data = false;
            const UserData = await postUsersData("/auth/v1/insertorupdateuserrights", savedata);
            status = UserData.status;
            data = UserData.data;
            if ((!status && data === null) || (!status && !data)) {
                dispatch({ type: 'ALERTING', message: UserData.message })
                return;
            }
            else {
                if (status && data > 0) {
                    dispatch({ type: 'ALERTING', message: UserData.message })
                    handleClearClick();
                }
                else {
                    dispatch({ type: 'ALERTING', message: UserData.message })
                    handleClearClick();
                    return;
                }
            }
        }

    }
    const handleChangeCheck = (e, menuId) => {
        if (userId == 0) {
            // dispatch(alertActions.error('please select Role'));
        }
        else {
            let Vuserdetail = userrights;
            let Rights = Vuserdetail.filter((t) => t.Menu_Id === menuId);
            if (e.target.value === "All") {
                if (e.target.checked) {
                    Rights[0].uRoleAll = true;
                    Rights[0].uRoleView = true;
                    Rights[0].uRoleExport = true;
                    Rights[0].uRoleAdd = true;
                    Rights[0].uRoleEdit = true;
                }
                else {
                    Rights[0].uRoleAll = false;
                    Rights[0].uRoleView = false;
                    Rights[0].uRoleExport = false;
                    Rights[0].uRoleAdd = false;
                    Rights[0].uRoleEdit = false;
                }
            }

            else if (e.target.value === "Add") {
                Rights[0].uRoleAdd = Rights[0].uRoleAdd === true ? false : true;
            }
            else if (e.target.value === "Edit") {
                Rights[0].uRoleEdit = Rights[0].uRoleEdit === true ? false : true;
            }
            else if (e.target.value === "View") {
                Rights[0].uRoleView = Rights[0].uRoleView === true ? false : true;
            }
            else if (e.target.value === "Export") {
                Rights[0].uRoleExport = Rights[0].uRoleExport === true ? false : true;
            }
            if (Rights[0].uRoleView === true && Rights[0].uRoleExport === true && Rights[0].uRoleAdd === true && Rights[0].uRoleEdit === true) {
                Rights[0].uRoleAll = true;
            }
            if (Rights[0].uRoleView != true) {
                Rights[0].uRoleView = Rights[0].uRoleExport || Rights[0].uRoleAdd || Rights[0].uRoleEdit;
            }
            if (!Rights[0].uRoleView || !Rights[0].uRoleExport || !Rights[0].uRoleAdd || !Rights[0].uRoleEdit) {
                Rights[0].uRoleAll = false;
            }
            let fnlUserRight = Vuserdetail.map((user) => {
                if (user.Menu_Id == menuId) {
                    return Rights[0];
                }
                return user;
            })
            Vuserdetail = fnlUserRight;
            setUserRights(Vuserdetail);
            setDataSave({ ...savedata, UserMasterId: userId, UserRightsList: Vuserdetail })

        }
    }
    const handleClearClick = () => {
        setUserId(0);
    }
    useEffect(() => {
        getUserRigths();
        getUserList();
    }, []);

    return (
        <>
            <div className="container mt-4 heading_color">
                <h3>User Rights</h3>
            </div>
            <div class="container form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {/* {galleryduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {galleryduplicateerrorMessage} </div>} */}
                                <div className='col-md-6 mb-lg-1'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label required">Select User</label>
                                        </div>
                                        <div class=" col-lg-8 col-md-12 col-sm-12">
                                            <Select className="dropdown select_dropdown"
                                                options={userList}
                                                value={userList?.filter(function (userList) {
                                                    return userList.value === userId;
                                                })}
                                                onChange={handleUserchange}
                                            // isDisabled={disabled ? true : false}
                                            />
                                            {/* {cityerrorMessage && <div className="text-danger"> {cityerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <div className='overflow-auto'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th className='ps-4' scope="col">Title</th>
                                        <th scope="col">All</th>
                                        <th scope="col">View</th>
                                        <th scope="col">Add</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">Export</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userrights && userrights?.map((el) => {
                                        return (
                                            <>
                                                {
                                                    (el.MenuType === 0 && el.PageTitle === "Dashboard") ?
                                                        <>
                                                            <tr>
                                                                <th className='ps-4' width="275px" scope="row">{el.PageTitle}</th>
                                                                <td><input className="form-check-input" checked={el.uRoleAll} name='All' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="All" /></td>
                                                                <td><input className="form-check-input" checked={el.uRoleView} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="View" /></td>
                                                                <td><input className="form-check-input" checked={el.uRoleAdd} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Add" /></td>
                                                                <td><input className="form-check-input" checked={el.uRoleEdit} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Edit" /></td>
                                                                <td><input className="form-check-input" checked={el.uRoleExport} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Export" /></td>
                                                            </tr>
                                                        </> : ''
                                                }
                                                {
                                                    (el.MenuType === 1) ?
                                                        <>
                                                            <tr>
                                                                <th className='admin_btn' width="275px" onClick={() => handleExpandAdmin(el.ParentId)} scope="row"><i class={`fa fa-angle-down me-1 ${toggleAdmin.filter((val1) => (val1 === el.ParentId))?.map((val) => val === el.ParentId ? 'rotet' : '')}`} aria-hidden="true"></i>{el.PageTitle}</th>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </> : ''
                                                }
                                                {toggleAdmin.filter((val1) => (val1 === el.ParentId))?.map((val) => {
                                                    return (
                                                        (el.MenuType === 0) ?
                                                            <>
                                                                <tr>
                                                                    <th className='ps-4' width="275px" scope="row">{el.PageTitle}</th>
                                                                    <td><input className="form-check-input" checked={el.uRoleAll} name='All' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="All" /></td>
                                                                    <td><input className="form-check-input" checked={el.uRoleView} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="View" /></td>
                                                                    <td><input className="form-check-input" checked={el.uRoleAdd} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Add" /></td>
                                                                    <td><input className="form-check-input" checked={el.uRoleEdit} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Edit" /></td>
                                                                    <td><input className="form-check-input" checked={el.uRoleExport} name='el.PageTitle' onChange={(e) => handleChangeCheck(e, el.Menu_Id)} type='checkbox' value="Export" /></td>
                                                                </tr>
                                                            </> : ''
                                                    )
                                                })}

                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {(useradd === true || useredit === true) ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" disabled={userId > 0 ? false : true} onClick={handlesavadata} >Save</button>
                            :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handlesavadata} disabled={true} >Save</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}>Clear</button>
                    </div>
                </form >
            </div >
        </>
    )
}

export default Userrights