import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/homepage.css';
import './assets/css/cmslandingpage.css'
import './assets/css/master.css'
import { store } from "./_helpers/store";
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './components/admin/Login';
import APP from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/*' element={<APP />} />
        <Route path='/Logout' element={<Login />} />
      </Routes>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
