import React, { Children } from 'react'
import { useStore } from "react-redux";
import { Modal } from '../../_helpers/Modal';
import { Oval } from "react-loader-spinner";
// import RegisterUser from '../../_actions/RegisterAction';
const LandingPageDesign = ({ children }: any) => {
    const store = useStore<any>().getState();

    return (
        <>
            <Modal show={store.loader.load}>
                <div className="loader">
                    <Oval
                        height={200}
                        width={200}
                        color="#122d59"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#122d59"
                        strokeWidth={3}
                        strokeWidthSecondary={3}
                    /></div>
            </Modal>
            <div className='comntainer-fluid m-0'>
                <div className='row full_height bg_newblue m-0 position-relative'>
                    <div className='col-lg-9 col-md-12 bg-white login_border'>

                        <div className="mt-3 mb-5">
                            <div className="text-start">
                                <img src={require('../../assets/img/ETM-logo1.png')} alt="" width="100px" />
                            </div>
                        </div>

                        <div className='content_landing d-flex justify-content-center align-items-center'>
                            <div className="col-lg-4">
                                {children}
                            </div>
                        </div>
                        {/* <div className=' m-2 login_logo'>
                            <div className='h-100 d-flex p-auto'>
                                <img className='d-flex m-auto' src='/assets/img/ETM-logo1.png' alt='ETM-logo' width="150px" height="100px"></img>
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>

        </>
    )
}
export default LandingPageDesign