/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef } from 'react';
import './assets/css/cmslandingpage.css'
import { useState, useEffect } from 'react';
import { Route, Routes, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, connect } from "react-redux";
import { useSelector } from 'react-redux';
import { getAllData, verifytoken } from './services/ApiCallingUserServices';
import { ClearLocalStorage } from './components/commonClass/Session';
import { loaderActions } from './_actions/loader.actions';
import { Alert } from './_helpers/Alert';
import { Modal } from './_helpers/Modal';
import { Oval } from "react-loader-spinner";
import Userlist from './components/admin/Userlist';
import Useradd from './components/admin/Useradd';
import Rolerights from './components/admin/Rolerights';
import Userrights from './components/admin/Userrights';
import Dashboard from './components/pages/Dashboard';
const App = (props :any) => {
    const dispatch = useDispatch();
    const alertness = useSelector((store: any) => store.alertMess)
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false)
    const [show, setShow] = useState(0);
    const [MenuItem, setMenuItem] = useState([]);
    const [toggle, setToggle] = useState(false);

    const getMenuItem = async () => {
        const MenuItem = await getAllData('/master/v1/getallmenuitem');
        console.log('menu', MenuItem.data);
        setMenuItem(MenuItem.data);
    };
    const handleClick = () => {
        setMenuOpen(menuOpen => !menuOpen);
    };
    const submenutoggle = useRef(null);

    const handleShow = (e:any, atr:any, menutype:any) => {

        // console.log('className 👉️', e);
        //let l = document.getElementsByClassName('collapsed');
        if (e.currentTarget !== undefined) {
            if (menutype === 0) {
                //e.currentTarget.classList.toggle('isactive');  
                e.currentTarget.classList.add("isactive");
                setShow(atr)
                setToggle(!toggle);
                scrollDown(atr);

                let element = document.getElementsByClassName('submenutoggle');
                const arr = Array.from(element);
                arr.forEach(element => element.classList.add('collapsed'));
                let elementsub = document.getElementsByClassName('submenutoggles');
                const arr1 = Array.from(elementsub);
                arr1.forEach(element => element.classList.add('collapse'));
            }
            else if (menutype === 2) {
                e.currentTarget.classList.add("isactive");
                setShow(atr)
                setToggle(!toggle);
                scrollDown(atr);

                let element = document.getElementsByClassName('submenutoggle');
                const arr = Array.from(element);
                arr.forEach(element => element.classList.add('collapsed'));
                let elementsub = document.getElementsByClassName('submenutoggles');
                const arr1 = Array.from(elementsub);
                arr1.forEach(element => element.classList.add('collapse'));
            }
            else {

                let element = document.getElementsByClassName('isactive');
                const arr = Array.from(element);
                arr.forEach(element => element.classList.remove('isactive'));


                e.currentTarget.classList.toggle('collapsed');
                setShow(atr)
                setToggle(!toggle);
                scrollDown(atr);
            }
        }


    }
    const scrollDown = (reff :any) => {
        const submenu = document.getElementsByClassName(reff);
        // console.log('submenu' ,submenu)
        const arr = Array.from(submenu);
        arr.forEach(element => element.classList.toggle('collapse'));
    };
    const handleLogout = () => {
        ClearLocalStorage();
        navigate('/');
    }
    useEffect(() => {


        getMenuItem();
    }, []);

    useEffect(() => {

        async function verifyTokenValue() {
            let statusoftoken = await verifytoken();
            if (!statusoftoken) {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                dispatch(loaderActions.end())
            }

        }
        verifyTokenValue();
    }, [navigate])
    useEffect(() => {
        if (MenuItem?.length > 0) {
            dispatch(loaderActions.end());
        }
    }, [MenuItem])
    return (
        <>
            <Alert alert={alertness.isSuccess} alertmessage={alertness.isSuccessMessage} session={alertness.isSessionout} message={alertness.message} ></Alert>

            <Modal show={props.load}>
                <div className="loader">
                    <Oval
                        height={200}
                        width={200}
                        color="#122d59"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#122d59"
                        strokeWidth={3}
                        strokeWidthSecondary={3}


                    /></div>
            </Modal>
            <div className={`sb-nav-fixed ${(menuOpen ? 'sb-sidenav-toggled' : 'inactive')}`}>
                <nav className="sb-topnav navbar navbar-expand">
                    <a className="navbar-brand ps-3" href="index.html"></a>
                    <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" onClick={handleClick} id="sidebarToggle"><i className="fa fa-bars" aria-hidden="true"></i></button>

                    <ul className="navbar-nav ms-auto me-1 me-md-3 my-2 me-lg-3">
                        <a className="nav-link setc" role="button" onClick={handleLogout} data-bs-toggle="tooltip" data-bs-placement="left" title='Logout'><i className="fa fa-2x fa-sign-out" aria-hidden="true"></i></a>
                    </ul>
                </nav>
                <div id="layoutSidenav">
                    <div id="layoutSidenav_nav">
                        {/* <Menu/> */}
                        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                            <div className="sb-sidenav-menu ">
                                <div className="nav border_main">
                                    {MenuItem?.map((curElem :any) => {
                                        if (curElem.seqNo === 0) {
                                            return (
                                                <div className="sb-sidenav-menu-heading"><img className='d-flex mx-auto' src='/assets/img/ETM-logo1.png' alt='ETM-logo' width="50%" height="50px"></img></div>

                                            )
                                        }
                                        else {
                                            if (curElem.menuType === 0) {
                                                return (
                                                    <NavLink onClick={(e) => handleShow(e, curElem.parentId, curElem.menuType)} id={curElem.parentId} className={`nav-link link_border align-items-center desh_link `} to="/*">
                                                        <div className="sb-nav-link-icon">
                                                            <i className="bi bi-speedometer2" aria-hidden="true"></i>
                                                        </div>
                                                        {curElem.pageTitle}
                                                    </NavLink>
                                                )
                                            }
                                            else if (curElem.menuItemCollection === null && curElem.menuType === 2) {
                                                return (
                                                    <NavLink onClick={(e) => { handleShow(e, curElem.parentId, curElem.menuType); }} className={`nav-link link_border align-items-center desh_link `} to={curElem.pageLink}>
                                                        <div className="sb-nav-link-icon">
                                                            <i className="bi bi-layers" aria-hidden="true"></i>
                                                        </div>
                                                        {curElem.pageTitle}
                                                    </NavLink>
                                                )
                                            }
                                            else if (curElem.menuItemCollection === null) {
                                                return (
                                                    <NavLink onClick={(e) => { handleShow(e, curElem.parentId, curElem.menuType); }} className={`nav-link link_border master_link  ${(show === curElem.parentId ? '' : 'collapsed')} `} to="#">
                                                        <div className="sb-nav-link-icon">
                                                            <i className="bi bi-grid" aria-hidden="true"></i>
                                                        </div>
                                                        {curElem.pageTitle}
                                                        <div className="sb-sidenav-collapse-arrow">
                                                            <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                                        </div>
                                                    </NavLink>
                                                )
                                            }
                                            else {
                                                return (
                                                    <>
                                                        <NavLink onClick={(e) => { handleShow(e, curElem.parentId, curElem.menuType); }} className={`nav-link link_border master_link collapsed submenutoggle`} to="#" >
                                                            <div className="sb-nav-link-icon">
                                                                <i className="bi bi-grid" aria-hidden="true"></i>
                                                            </div>
                                                            {curElem.pageTitle}
                                                            <div className="sb-sidenav-collapse-arrow">
                                                                <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                                            </div>
                                                        </NavLink>

                                                        {curElem.menuItemCollection?.map((oCol :any) =>
                                                            <div ref={submenutoggle} className={`${oCol.parentId} collapse submenutoggles`} aria-labelledby="headingOne" >
                                                                <nav className="sb-sidenav-menu-nested nav link_border">
                                                                    <NavLink className="nav-link border_gbg" to={oCol.pageLink}>
                                                                        <div className="sb-nav-link-icon"><i className="bi bi-layers" aria-hidden="true"></i>
                                                                        </div>
                                                                        {oCol.pageTitle}
                                                                    </NavLink>
                                                                </nav>
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            }
                                        }
                                    })}
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div id="layoutSidenav_content" className='div_bgcolor'>
                        <div className="container-fluid h-100">
                            <Routes>
                                <Route path="/*" element={<Dashboard />} />
                                <Route path='/Userlist' element={<Userlist/>}/>
                                <Route path='/Useradd' element={<Useradd/>}/>
                                <Route path='/Rolerights' element={<Rolerights/>}/>
                                <Route path='/Userrights' element={<Userrights/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
function mapState(state :any) {
    const { load } = state.loader;
    return { load };
}
const APP = connect(mapState)(App);
export default APP;
// export default CMS