import {combineReducers} from 'redux';
import { loader } from './loader.reducer';
import { alertMess } from './alert.reducer';
import { errorStatus } from './isError.reducer';
import { userstatus } from './user.reducer';
import { menuid } from './user.reducer';

const rootReducer=combineReducers({
    loader, alertMess, errorStatus , userstatus, menuid,
})
export default rootReducer;