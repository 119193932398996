import React, { useEffect, useState } from 'react'
import { loaderActions } from '../../_actions/loader.actions';
import { SetLocalStorage } from '../commonClass/Session';
import { getAllData, getDataByPara } from '../../services/ApiCallingUserServices';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import LandingPageDesign from './LandingPageDesign';
import { LoginModel } from './Modals/LoginModel';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState(new LoginModel());
    const [formErrors, setFormErrors] = useState<any>({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [texttype, setTextType] = useState('password');
    const [eye, seteye] = useState(true);
    const [type, settype] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [usermain, setUserMain] = useState([]);

    const handleOnChange = (e: any) => {
        setFormErrors({})
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };
    //Window.$name = '';

    const onSubmitForm = (e: any) => {
        e.preventDefault();
        setFormErrors(validate(form));

    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleSubmitData();
            setDisabled(true);
        }
    }, [formErrors]);

    const validate = (values: any) => {
        const errors: any = {};
        setIsSubmit(true);
        if (!values.username && !values.password) {
            errors.credentials = "Kindly enter Username and Password";
            setIsSubmit(false);
        }
        if (!values.username && values.password) {
            errors.username = "Kindly enter Username";
            setIsSubmit(false);
        }
        if (values.username && !values.password) {
            errors.password = "Kindly enter your Password";
            setIsSubmit(false);
        }
        return errors;
    };
    const getUserById = async () => {
        await getAllData('/master/v1/getuserrightsforvisibility').then((userdata) => {
            setUserMain(userdata.data);
            dispatch({ type: 'USERDATA', tba: userdata.data })
        })
    }

    const handleSubmitData = async () => {
        dispatch(loaderActions.start());
        const userData = await getDataByPara('/auth/v1/getuserlogin', form)
        const userDatad = userData.data;
        //localStorage.setItem('user', JSON.stringify(userData.data));
        let username = '';
        let userId = 0;
        if (userData.status && form.password === userData.data.password) {
            SetLocalStorage(userData.data);
            getUserById();

            userId = userData.data.userMasterID;
            username = userData.data.username;
            navigate('/cmsdashboard/*', { state: { username: username } });
        }
        else {
            dispatch(loaderActions.end());
            const errors: any = {};
            errors.credentials = userData.message;
            setFormErrors(errors);
            setIsSubmit(false)
            setDisabled(false);
        }

        setLoginError('');
    }
    const Eye = () => {
        if (texttype === "password") {
            setTextType("text");
            seteye(false);
            settype(true);
        }
        else {
            setTextType("password");
            seteye(true);
            settype(false);
        }
    }
    const handleForgotPassword = () => {
        // navigate("")
    }

    return (
        <>
            <LandingPageDesign>
                <div className="card border-0 rounded-lg">
                    <div className="card-header bg-body border-0">
                        <h2 className="text-center font-weight-light my-2 color_newblue fw-bold">Log In</h2>
                    </div>
                    <div className="card-body">
                        <form onSubmit={onSubmitForm}>
                            <div className='row'>
                                <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                                    {loginError && <p className='text-primary text-start mb-0'>{loginError}</p>}
                                    {formErrors.wrongpsw && <p className='text-primary text-start mb-0'>{formErrors.wrongpsw}</p>}
                                    {formErrors.credentials && <p className='text-danger text-center'>{formErrors.credentials}</p>}
                                </div>
                                <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                                    <label htmlFor="userid" className="col-form-label text-muted" ><i className="fa fa-user me-2 color_newblue" aria-hidden="true"></i>Username</label>
                                </div>
                                <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                                    <input className="form-control"
                                        type="text"
                                        id='userid'
                                        placeholder="Username"
                                        name="username"
                                        value={form.username}
                                        onChange={(e: any) => handleOnChange(e)}
                                        disabled={disabled ? true : false}
                                        maxLength={30}
                                    />
                                    {formErrors.username && <p className='text-danger text-start mb-0'>{formErrors.username}</p>}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                                    <label htmlFor="passwordfield" className="col-form-label text-muted" ><i className="fa fa-key me-2 color_newblue" aria-hidden="true"></i>Password</label>
                                </div>
                                <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                                    <input className="form-control password_valid" id="passwordfield"
                                        type={texttype}
                                        name="password"
                                        placeholder="Password"
                                        value={form.password}
                                        onChange={(e: any) => handleOnChange(e)}
                                        disabled={disabled ? true : false}
                                        maxLength={15}
                                    />
                                    <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"} p-viewer`} ></i>
                                    {formErrors.password && <p className='text-danger text-start mb-0'>{formErrors.password}</p>}

                                </div>
                            </div>
                            <div className='row'>
                                <div className="d-flex justify-content-end mt-2">
                                    <button type='button' className="small forgotpsw forgot_Button" onClick={() => handleForgotPassword()}>Forgot Password?</button>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4 mb-0">
                                    <button className="btn btn-primary col-xxl-12 col-lg-12 col-md-12 col-sm-12" disabled={disabled ? true : false}>Log In</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </LandingPageDesign>
        </>
    )
}

export default Login
